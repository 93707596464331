import React from "react";
import styles from "./HeaderDesc.module.css";
import PropTypes from "prop-types";

const HeaderDesc = ({ isBusiness }) => {
  return isBusiness ? (
    <div className={styles.businessHeaderWrapper}>
      <div className={styles.businessHeader}>
        Let’s create your business account
      </div>
    </div>
  ) : (
    <h2 className={styles.header}>Let’s sign you up</h2>
  );
};

export default HeaderDesc;
HeaderDesc.propTypes = {
  isBusiness: PropTypes.bool,
};
