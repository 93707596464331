import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { USER_TYPES } from "../../constants";
import useGetUser from "../../hooks/useGetUser";
import { checkUserRole } from "../../utils";
import { externalLinks } from "../../constants/externalLinks";

const JoinRedirection = () => {
  const navigate = useNavigate();

  const { userData, userRole, loading } = useGetUser();

  const isConsumerUser = checkUserRole(userRole, USER_TYPES.standard);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (userData && !isConsumerUser) {
      navigate("/business-dashboard");
      return;
    }

    window.location.href = externalLinks.BUSINESS_REGISTER;
  }, [loading, userData, isConsumerUser]);

  return null;
};

export default JoinRedirection;
