import React, { useEffect, useState } from "react";
import styles from "./ConfirmEmail.module.css";
import Input from "../../components/GlobalComponents/UI/Input/Input";
import RegisterNextBtn from "../../components/GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import { useMutation, useReactiveVar } from "@apollo/client";
import confirmEmail from "../../graphql/mutations/authentication/confirmEmail";
import resendEmailConfirmation from "../../graphql/mutations/authentication/resendEmailConfirmation";
import loginMutation from "../../graphql/mutations/authentication/login.js";
import Spinner from "../../components/GlobalComponents/UI/Spinner/Spinner";
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";
import { notVerifiedUserData } from "../../utils/cache";
import { checkUserRole } from "../../utils";
import { USER_TYPES } from "../../constants";
import useLogin from "../../hooks/useLogin";
import { searchParamsKeys } from "../../constants/searchParamsKeys.js";

const CONSUMER_USER_TYPE = "consumer user";
const BUSINESS_USER_TYPE = "business user";

const ConfirmEmail = () => {
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState("");
  const [valid, setValid] = useState(false);
  const [changingWord, setChangingWord] = useState("sent");
  const [verify, { loading: verifyLoading, error, reset }] =
    useMutation(confirmEmail);
  const { password } = useReactiveVar(notVerifiedUserData) ?? {};
  const [login, { client, loading: loginLoading }] = useMutation(loginMutation);

  const loading = verifyLoading || loginLoading;
  const email = searchParams.get(searchParamsKeys.email);

  const [
    resendVerification,
    { data: resendData, error: resendError, loading: resendLoading },
  ] = useMutation(resendEmailConfirmation);
  const navigate = useNavigate();
  const [saveUserData, onSuccesHandler] = useLogin();

  const codeHandler = (e) => {
    setCode(e.target.value);
    reset();
  };
  const handleKeypress = (e) => {
    if (e.keyCode === 13 && valid) {
      confirmEmailHandler();
    }
  };
  useEffect(() => {
    if (code.length === 6) {
      setValid(true);
      return;
    } else {
      setValid(false);
    }
  }, [code]);
  useEffect(() => {
    if (resendData) {
      setChangingWord("resent");
    }
  }, [resendData]);

  useEffect(() => {
    return () => notVerifiedUserData();
  }, []);

  const segmentIdentifierHandler = (user) => {
    analytics.identify(user.id, {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      sign_up_date: user?.createdAt,
      user_type: checkUserRole(user?.roles, USER_TYPES?.standard)
        ? CONSUMER_USER_TYPE
        : BUSINESS_USER_TYPE,
      email_subscribe: "Subscribed",
      user_comments_count: 0,
      user_upvotes_count: 0,
      user_recent_search_history: [],
      user_session_count: 0,
    });
    if (checkUserRole(user?.roles, USER_TYPES?.standard)) {
      analytics.track("new_consumer_user_created");
    }
  };

  const confirmEmailHandler = () => {
    verify({ variables: { email, code } }).then(({ data }) => {
      analytics.track("user_email_verified");
      segmentIdentifierHandler(data?.verifyEmail);
      login({ variables: { username: email, password } })
        .then(({ data }) => {
          saveUserData(data.login);
          client.resetStore();
          return data;
        })
        .then((data) => {
          onSuccesHandler(data?.login);
        })
        .catch(() => navigate("/login"));
    });
  };

  const resendEmail = () => {
    resendVerification({ variables: { email } });
  };
  if (!email) {
    return <Navigate to='/' />;
  }

  return (
    <div className={styles.confirmContainer}>
      <h6 className={styles.header}>Confirm your email</h6>
      <p className={styles.subTitle}>
        We’ve{" "}
        <span className={`${styles.resent} ${resendData && "notifyAnimation"}`}>
          {changingWord}{" "}
        </span>
        a code to {email}
      </p>
      <Input
        hasLabel={true}
        label='ENTER 6 DIGIT CODE'
        placeholder='Confirmation code'
        type='text'
        inpContainerClass={`${styles.inpContainer} ${error && styles.invalid}`}
        value={code}
        changeHandler={codeHandler}
        max={6}
        invalid={error ? true : false}
        errorMessage={error?.message}
        keyDownHandler={handleKeypress}
      />
      <p className={styles.resendText}>
        Didn’t get the email? Check your spam / junk or{" "}
        <button className={styles.resendBtn} onClick={resendEmail}>
          resend it
        </button>
        .
      </p>
      <RegisterNextBtn
        variant={styles.finishBtn}
        disabled={!valid}
        clickHandler={confirmEmailHandler}
        isLoading={loading || resendLoading}
      >
        {loading || resendLoading ? <Spinner /> : "FINISH"}
      </RegisterNextBtn>
    </div>
  );
};

export default ConfirmEmail;
