import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useInput from "../../../hooks/useInput";
import {
  emailValidationRules,
  isRequired,
} from "../../../utils/validationRules";
import Input from "../../GlobalComponents/UI/Input/Input";
import RegisterNextBtn from "../../GlobalComponents/Authentication/RegisterNextBtn/RegisterNextBtn";
import styles from "./SignInForm.module.css";
import loginMutation from "../../../graphql/mutations/authentication/login.js";
import { useMutation } from "@apollo/client";
import { notVerifiedUserData } from "../../../utils/cache";
import Spinner from "../../GlobalComponents/UI/Spinner/Spinner";
import resendEmailConfirmation from "../../../graphql/mutations/authentication/resendEmailConfirmation";
import useLogin from "../../../hooks/useLogin";
import { searchParamsKeys } from "../../../constants/searchParamsKeys.js";
const SignInForm = () => {
  const [login, { client, loading, error }] = useMutation(loginMutation);
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showVerificationLink, setShowVerification] = useState(false);
  const [verify, { loading: verifyLoading, error: verifyError }] = useMutation(
    resendEmailConfirmation
  );
  const {
    value: email,
    hasError: emailError,
    errorMessage: emailErrorMessage,
    valueChangeHandler: emailHandler,
    valueBlurHandler: emailBlurHandler,
    isValid: emailValid,
  } = useInput(emailValidationRules);

  const {
    value: password,
    hasError: passwordError,
    errorMessage: passwordErrorMessage,
    valueChangeHandler: passwordHandler,
    valueBlurHandler: passwordBlurHandler,
    isValid: passwordValid,
  } = useInput(isRequired);

  const [saveUserData, onSuccesHandler] = useLogin();
  const navigate = useNavigate();
  const formIsValid = emailValid && passwordValid;

  useEffect(() => {
    if (loginError) {
      setLoginError(false);
      setErrorMessage(null);
    }
  }, [email, password]);
  useEffect(() => {
    if (error) {
      setLoginError(true);
      setErrorMessage(error?.message);
      if (
        error?.message ===
        "The e-mail associated with this account was not verified yet"
      ) {
        setShowVerification(true);
        notVerifiedUserData({ email, password });
      }
    }
  }, [error]);

  const clickHandler = (e) => {
    e.preventDefault();
    login({ variables: { username: email, password } })
      .then(({ data }) => {
        saveUserData(data.login);
        client.resetStore();
        return data;
      })
      .then((data) => {
        onSuccesHandler(data?.login);
      });
  };
  const verifyEmail = () => {
    verify({ variables: { email } }).then(() => {
      navigate(
        `/confirm-email?${searchParamsKeys.email}=${encodeURIComponent(email)}`
      );
    });
  };
  const stopError = (e) => {
    e.preventDefault();
  };
  return (
    <form className={styles.loginForm} onSubmit={clickHandler}>
      <h2 className={styles.header}>Let’s log you in, fam</h2>
      <Input
        hasLabel
        isRequired
        label='EMAIL'
        placeholder='e.g. yourname@gmail.com'
        type='email'
        inpContainerClass={`${styles.inpContainer} ${
          (emailError || loginError) && styles.invalid
        }`}
        value={email}
        changeHandler={emailHandler}
        blurHandler={emailBlurHandler}
        invalid={emailError}
        errorMessage={emailErrorMessage}
      />
      <Input
        hasLabel
        isRequired
        label='PASSWORD'
        placeholder='Enter password'
        type='password'
        inpContainerClass={`${styles.inpContainer} ${
          (passwordError || loginError) && styles.invalid
        }`}
        value={password}
        changeHandler={passwordHandler}
        blurHandler={passwordBlurHandler}
        invalid={passwordError}
        errorMessage={passwordErrorMessage}
      />
      {loginError && (
        <p className={styles.errorMessage}>
          {errorMessage}{" "}
          {showVerificationLink && (
            <button onClick={verifyEmail} className={styles.verify}>
              {"  "}Click here to verify your email
            </button>
          )}
        </p>
      )}
      <div className={styles.btnLinkContainer}>
        <NavLink
          to='/forgot-password'
          className={styles.forgot}
          onMouseDown={stopError}
        >
          Forgot password?
        </NavLink>
        <NavLink
          to='/consumer-register'
          className={styles.signup}
          onMouseDown={stopError}
        >
          Click here to sign up!
        </NavLink>
        <RegisterNextBtn
          variant={styles.continueBtn}
          disabled={!formIsValid}
          isLoading={(loading || verifyLoading) && formIsValid}
        >
          {(loading || verifyLoading) && formIsValid ? <Spinner /> : "CONTINUE"}
        </RegisterNextBtn>
      </div>
    </form>
  );
};

export default SignInForm;
