import React, { useEffect, useState } from "react";
import RegisterForm from "../../components/ConsumerRegister/RegisterForm/RegisterForm";
import BackButton from "../../components/GlobalComponents/Buttons/BackButton/BackButton";
import SignUpPicture from "../../components/GlobalComponents/Authentication/SignUpPicture/SignUpPicture";
import SignUpTerms from "../../components/GlobalComponents/Authentication/SignUpTerms/SignUpTerms";
import styles from "./ConsumerRegister.module.css";
import { useMutation } from "@apollo/client";
import consumerRegister from "../../graphql/mutations/authentication/consumerRegister";
import { useNavigate } from "react-router-dom";
import { searchParamsKeys } from "../../constants/searchParamsKeys";

const ConsumerRegister = () => {
  const [userData, setUserData] = useState({});
  const [registerStepIndex, setRegisterStepIndex] = useState(0);
  const [registerConsumer, { loading: consumerLoading, error: consumerError }] =
    useMutation(consumerRegister);
  const navigate = useNavigate();

  const nextStepHandler = () => {
    setRegisterStepIndex((registerIndex) => registerIndex + 1);
  };
  const previousStepHandler = () => {
    setRegisterStepIndex((registerIndex) => registerIndex - 1);
  };
  const insertUserData = (data) => {
    setUserData({ ...userData, ...data });
  };

  const submitHandler = () => {
    registerConsumer({
      variables: {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        password: userData?.password,
        email: userData?.email,
        imageID: userData?.imageID,
      },
    }).then(() => {
      analytics.track("terms_accepted");
      navigate(
        `/confirm-email?${searchParamsKeys.email}=${encodeURIComponent(email)}`
      );
    });
  };

  return (
    <div className={styles.registerPage}>
      <div className={styles.registerContainer}>
        {registerStepIndex === 0 && (
          <RegisterForm
            userData={userData}
            insertUserData={insertUserData}
            nextStepHandler={nextStepHandler}
          />
        )}
        {registerStepIndex !== 0 && (
          <BackButton
            variant={styles.backBtn}
            backHandler={previousStepHandler}
          />
        )}
        {registerStepIndex === 1 && (
          <SignUpPicture
            userData={userData}
            insertUserData={insertUserData}
            nextStepHandler={nextStepHandler}
            submitHandler={submitHandler}
          />
        )}
      </div>
    </div>
  );
};

export default ConsumerRegister;
