import { useReactiveVar } from "@apollo/client";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { isAdmin } from "../../../../utils/cache";
import regStyles from "./Footer.module.css";
import adminStyles from "./AdminStyles.module.css";
import { firstColItems } from "./footerItems";
import Logo from "../Logo/Logo";
import GetListedBtn from "../../Buttons/GetListedBtn/GetListedBtn";
import SocialLinks from "./SocialLinks";
import PlayStores from "./PlayStores";

const Footer = () => {
  const admin = useReactiveVar(isAdmin);
  const styles = admin ? adminStyles : regStyles;
  const { pathname } = useLocation();

  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={`${styles.footerSection} ${
        pathname === "/awards2022" ? styles.landingFooter : styles.regFooter
      }`}
    >
      <div className={styles.container}>
        <div className={styles.footerContainer}>
          <div className={styles.firstCol}>
            <PlayStores />
            <SocialLinks />
          </div>
          <ul className={styles.list}>
            {firstColItems.map((link, i) => (
              <li key={i} className={styles.item}>
                <NavLink to={admin ? link?.adminRoute : link.route}>
                  {link.item}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className={styles.list}>
            <li className={styles.item}>
              <a href='https://blog.obws.com/' rel='noreferrer' target='_blank'>
                Blog
              </a>
            </li>
            <li className={styles.item}>
              <NavLink to='/contact-us'>Contact Us</NavLink>
            </li>
            <li className={styles.item}>
              <GetListedBtn variant={styles.listed}>Get Listed</GetListedBtn>
            </li>
            <li className={styles.item}>
              <a
                href='https://www.obwsads.com/?r_done=1'
                rel='noreferrer'
                target='_blank'
              >
                Advertise With Us
              </a>
            </li>
            <li className={styles.item}>
              <a
                href='https://forms.gle/aTWnmwSWphetJkt89'
                rel='noreferrer'
                target='_blank'
              >
                Recommend a Business
              </a>
            </li>
          </ul>
          <ul className={styles.list}>
            <li className={styles.item}>
              <a
                href=' https://static.obws.com/docs/OBWS-ToS.pdf'
                rel='noreferrer'
                target='_blank'
              >
                Terms
              </a>
            </li>
            <li className={styles.item}>
              <a
                href='https://static.obws.com/docs/OBWS-Privacy-Policy.pdf'
                rel='noreferrer'
                target='_blank'
              >
                Privacy Policy
              </a>
            </li>
          </ul>
          <div className={styles.rightsContainer}>
            <Logo variant={styles.bottomLogo} />

            <p className={styles.rightsText}>
              All Rights Reserved, {currentYear}® Official Black Wall Street
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
